<template>
  <v-form @submit.prevent="uploadImage">
    <v-container>
      <Loading v-if="isLoading" />
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <thead>
              <tr>
                <th class="font-weight-bold">
                  {{ $t('global.name') }}
                </th>
                <th class="text-right">
                  {{ $t('global.age') }}
                </th>
                <th>{{ $t('global.sendAtk') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="index">
                <td>{{ item.member.firstname }}</td>
                <td class="text-right">
                  {{ item.member.birthday | formatAge }}
                </td>
                <td>
                  <div
                    v-if="item.atkUrl"
                    class="success--text px-4">
                    {{ $t('global.success') }}
                  </div>
                  <div v-else>
                    <v-btn
                      v-if="items[index].preview"
                      color="red"
                      text
                      @click="openImage(items[index].preview)"
                    >
                      {{ $t('global.view') }}
                    </v-btn>
                    <v-btn
                      v-if="items[index].preview"
                      color="red"
                      text
                      rounded
                      @click="clearImage(index)"
                    >
                      {{ $t('global.delete') }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      rounded
                      dark
                      text
                      @click="handleFileImport(index)"
                    >
                      {{ $t('global.addImage') }}
                    </v-btn>
                    <input
                      :ref="`uploader${index}`"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileChanged($event, index)"
                    />
                  </div>
                </td>
                <td>
                  <v-btn
                    v-if="item.atkUrl"
                    color="red"
                    text
                    @click="openImage(item.atkUrl)">
                    {{ $t('global.view') }}
                  </v-btn>
                  <v-btn
                    v-if="item.atkUrl"
                    color="red"
                    text
                    @click="handleFileImport(index)">
                    {{ $t('global.edit') }}
                  </v-btn>
                  <input
                    :ref="`uploader${index}`"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="onFileChanged($event, index)"
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            type="submit"
            class="mb-2"
            depressed
            block
            color="success">
            {{ $t('global.submit') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog
        v-model="isDialog"
        width="700">
        <img
          class="image-preview"
          :src="previewImage"
        />
      </v-dialog>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
// import AddImage from './components/AddImage.vue'

export default {
  components: {
    Loading
  },
  data () {
    return {
      isDialog: false,
      isLoading: false,
      previewImage: '',
      headers: [
        {
          text: this.$t('global.name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: this.$t('global.age'),
          align: 'start',
          sortable: false,
          value: 'age'
        },
        {
          text: 'ส่งหลักฐาน ATK',
          align: 'start',
          sortable: false,
          value: 'result'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'preview'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'edit'
        }
      ],
      items: []
    }
  },
  async mounted () {
    await this.checkLogined()
    await this.getAtk()
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    async getAtk () {
      try {
        this.isLoading = true
        const {
          data: { data }
        } = await this.axios.get(
          `${process.env.VUE_APP_API_URL}/atk/order/${this.$route.params.orderId}`
        )
        this.items = data
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async uploadImage () {
      try {
        this.isLoading = true
        const atk = []
        const atkId = []
        const datapost = []
        this.items.forEach((item) => {
          const formData = new FormData()
          if (item.image) {
            formData.append('file', item.image)
            atkId.push(item.atkId)
            datapost.push(
              this.axios({
                method: 'post',
                url: `${process.env.VUE_APP_API_URL}/upload`,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
              })
            )
          }
        })
        const data = await Promise.all(datapost)
        const atkUrl = data.map((item) => item.data.data.publicUrl)
        atkId.forEach((item, index) => {
          atk.push({
            atkId: item,
            atkUrl: atkUrl[index]
          })
        })
        await this.axios.patch(`${process.env.VUE_APP_API_URL}/atk`, { atk })
        this.$toast.success('ดำเนินการสำเร็จ', {
            position: 'top-right',
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: false,
            icon: true,
            rtl: false
          })
          this.$router.push('/history')
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    openImage (url) {
      this.isDialog = true
      this.previewImage = url
    },
    handleFileImport (index) {
      // this.$nextTick(() => {
      //   console.log(this.$refs[`uploader${index}`])
      // })
      this.$refs[`uploader${index}`][0].click()
    },
    clearImage (index) {
      delete this.items[index].image
      delete this.items[index].preview
      this.$forceUpdate()
    },
    onFileChanged (e, index) {
      this.items[index].image = e.target.files[0]
      this.items[index].preview = URL.createObjectURL(e.target.files[0])
      this.$forceUpdate()
    },
    // formatAge (date) {
    //   return formatDate.age(date)
    // },
    async checkLogined () {
      try {
        const { member } = JSON.parse(localStorage.getItem('member'))
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/member/${member.memberId}`)
        if (!data.member) {
          localStorage.removeItem('member')
          this.$router.push('/')
        }
      } catch (error) {
        console.error(error)
      }
    },
    successAlert () {
      try {
        this.$toast('My toast content', {
          timeout: 2000
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-input-image {
  padding: 0 !important;
  ::v-deep {
    .v-input__prepend-outer {
      display: none;
    }
    .v-input__slot::before {
      display: none;
    }
  }
}
.image-preview {
  max-height: 700px;
  max-width: 700px;
}
</style>
